<template>
    <nav class="navbar bg-body-tertiary">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions">
          <box-icon name='menu' animation='tada-hover'></box-icon>
        </button>
      </div>
    </nav>
    <aside>
      <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel" style="overflow-y: scroll; max-height: 100vh;">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">Menú</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body flex-shrink-0" data-bs-spy="scroll">
          <ul class="list-unstyled">
            <li class="mb-1">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#university-collapse" aria-expanded="false">
                Ver
              </button>
              <div class="collapse" id="university-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li><router-link to="/universidad/vacantes" class="link-dark rounded">Vacantes</router-link></li>
                  <!-- <li><router-link to="/informationCompany" class="link-dark rounded">Configuración</router-link></li> -->
                </ul>
              </div>
            </li>
            <li class="border-top my-3"></li>
            <li class="mb-1">
              <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                data-bs-target="#account-collapse" aria-expanded="false">
                Cuenta
              </button>
              <div class="collapse" id="account-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li><router-link to="/universidad/perfil" class="link-dark rounded">Perfil</router-link></li>
                  <!-- <li><router-link to="/informationCompany" class="link-dark rounded">Configuración</router-link></li> -->
                  <li><a href="#" class="link-dark rounded" @click="logout()">Cerrar Sesión</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  
    <body>
      <router-view />
    </body>
  
  
  </template>
  <script>
  import { universityService } from "@/services";


  export default {
    data() {
      return {
        statusCompany: false,
        id: "",
        dataUser: {},
        university: {},
        idUniversity: "",
      };
    },
    mounted() {
      this.setupDropdownPanelState();
    },
    beforeMount() {
      this.dataUser = JSON.parse(localStorage.getItem("user")) || null;
      if (this.dataUser && this.dataUser._id) {
        this.idUniversity = this.dataUser._id;
        this.getInformationCompany(this.idUniversity);
      } else {
        console.error("El usuario no está definido o no tiene un ID");
      }
    },
    methods: {
      async getInformationCompany(id) {
        try {
          const response = await universityService.getUniversity(id);
          const data = response.data.data;
          if (data.length > 0) {
            data.forEach((university) => {
              this.statusCompany = university.status;
              if (university.status === false) {
                this.showModal();
              }
            });
          }
        } catch (error) {
          console.error("Error en la llamada a la API:", error.message);
        }
      },
      showModal() {
        this.$swal({
          title:
            "<h5 style='Poppins'>Tu perfíl se encuentra en revisión, cuando sea aprobado serás notificado al correo electrónico de contacto.</h5>",
          icon: "info",
          confirmButtonText: 'Genial',
          confirmButtonAriaLabel: "Thumbs up, great!",
        });
      },
      setupDropdownPanelState() {
        const dropdownButtons = document.querySelectorAll(".offcanvas-body .btn-toggle");
        dropdownButtons.forEach((button) => {
          button.addEventListener("click", () => {
            const panelId = button.getAttribute("data-bs-target");
            const panelState = button.getAttribute("aria-expanded");
            localStorage.setItem(panelId, panelState);
          });
          const panelId = button.getAttribute("data-bs-target");
          const panelState = localStorage.getItem(panelId);
          if (panelState === "true") {
            button.setAttribute("aria-expanded", "true");
            const panel = document.querySelector(panelId);
            panel.classList.add("show");
          }
        });
      },
      logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        this.removeCookie("user");
        this.removeCookie("accessToken");
        window.location.href = "/";
      },
      removeCookie(name) {
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }
    }
  };
  
  </script>
  
  <style land="scss">
  @import "../../styles/empresa.layouts.scss";
  </style>